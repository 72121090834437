import { NetworkError } from "operations/error";

export const getFieldError = (error: Error, ...names: string[]) => {
  if (error instanceof NetworkError && names.length) {
    const errorName = names.find(name => error.getFirstFieldError(name));

    return (errorName && error.getFirstFieldError(errorName)) || error.message;
  }

  return error.message;
};

export const getErrorType = (error: Error, type = "Validation error") =>
  error.message === type;
