import { FormEvent, KeyboardEvent, ClipboardEvent, FocusEvent } from "react";

import { getValue } from "./selectors";
import { Context } from "./types";

export const keyDownHandler = (
  event: KeyboardEvent,
  index: number,
  { inputs, onChange }: Context,
) => {
  const previous = inputs[index - 1];
  const next = inputs[index + 1];
  const current = inputs[index];
  switch (event.key) {
    case "Backspace":
      event.preventDefault();
      if (!next?.value) {
        current.value = "";

        onChange?.(getValue(inputs));

        previous?.focus();
        previous?.select();
      }

      break;

    case "ArrowLeft":
      event.preventDefault();
      previous?.focus();
      previous?.select();
      break;

    case "ArrowRight":
      event.preventDefault();
      next?.focus();
      next?.select();
      break;

    case " ":
    case "ArrowUp":
    case "ArrowDown":
      event.preventDefault();
      break;

    default:
  }
};

const updateValue = ({ inputs, onChange, onFilled }: Context) => {
  const value = getValue(inputs);

  onChange?.(value);

  if (onFilled && value.length === inputs.length) {
    onFilled(value);
  }
};

export const inputHandler = (
  event: FormEvent<HTMLInputElement>,
  index: number,
  context: Context,
) => {
  const { inputs, prepareValue } = context;
  const next = inputs[index + 1];
  const current = event.target as HTMLInputElement;

  current.value = prepareValue(current.value);

  if (!current.value) {
    return;
  }

  next?.focus();
  next?.select();

  return updateValue(context);
};

export const pasteHandler = (
  event: ClipboardEvent<HTMLInputElement>,
  index: number,
  context: Context,
) => {
  event.preventDefault();
  const pasteValue = event.clipboardData.getData("text/plain");
  const replacedValue = pasteValue.replace(/\D/g, "");
  if (!replacedValue) {
    return;
  }

  const { inputs } = context;
  const values = Array.from(replacedValue);
  for (let i = index; i < inputs.length; i++) {
    inputs[i].value = values[i - index] || "";
  }

  const lastInput = inputs[inputs.length - 1];

  lastInput.focus();
  lastInput.select();

  return updateValue(context);
};

export const focusHandler = (
  event: FocusEvent<HTMLInputElement>,
  index: number,
  { inputs }: Context,
) => {
  event.preventDefault();

  const input = event.target.value
    ? event.target
    : inputs[inputs.findIndex(input => !input.value)];

  input?.focus();
  input?.select();
};
