export const getTruncatedAddress = (address: string, length = 6) => {
  const addressLength = address.length;

  if (addressLength <= length * 2) {
    return address;
  }

  return `${address.substring(0, length)}…${address.substring(
    addressLength - length,
  )}`;
};
