import dialogAPI from "operations/dialog";

import SignInModal from "./SignInModal";

import { Steps } from "./duck/types";

interface OpenModalOptions {
  step: Steps;
}

const openModal = (options?: OpenModalOptions) =>
  dialogAPI.open(<SignInModal initialStep={options?.step} />);

export default openModal;
