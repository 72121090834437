import { FC, useState } from "react";

import accountAPI from "api/account";

import { Modal } from "components/dialog";

import SendEmail from "./components/send-email";
import VerifyCode from "./components/verify-code";
import Success from "./components/success";

import { Steps } from "./duck/types";

interface Props {
  initialStep?: Steps;
}

const STEP_COMPONENTS = [SendEmail, VerifyCode, Success];

const SignInModal: FC<Props> = ({ initialStep = Steps.sendEmail }) => {
  const [step, setStep] = useState(initialStep);
  const StepComponent: FC<{
    nextStep: VoidFunction;
  }> = STEP_COMPONENTS[step];

  return (
    <Modal
      onClosed={() => {
        accountAPI.codeTimes.clear();
      }}
    >
      <Modal.StepHeader
        onBack={
          step === Steps.verifyCode
            ? () => {
                accountAPI.codeTimes.change({ isCanceled: true });
                setStep(current => current - 1);
              }
            : null
        }
      >
        Step {step + 1} of 3
      </Modal.StepHeader>
      <StepComponent nextStep={() => setStep(current => current + 1)} />
    </Modal>
  );
};

export default SignInModal;
