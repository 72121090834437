import accountAPI from "api/account";

export const getTimerTick = () => {
  const value = accountAPI.codeTimes.value;
  if (!value) {
    return null;
  }

  return accountAPI.RESEND_CODE_DELAY - (Date.now() - value.time);
};
