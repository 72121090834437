import { useRef } from "react";
import { SubmitHandler, useForm } from "react-hook-form";

import PointingUpEmoji from "emojis/pointing-up.svg?react";
import EnvelopEmoji from "emojis/envelop.svg?react";

import accountAPI from "api/account";

import Input from "basics/input";
import Button from "basics/button";

import { openAlert } from "components/dialog";

import { getErrorType, getFieldError } from "selectors/errors";

import { FormValues } from "./duck/types";

import signInModalClasses from "../../styles/classes.module.scss";
import classes from "./styles/classes.module.scss";

interface Props {
  nextStep: VoidFunction;
}

const SendEmail = ({ nextStep }: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const {
    setError,
    handleSubmit,
    register,
    formState: { errors, isSubmitting, isValid },
  } = useForm({
    defaultValues: { email: accountAPI.codeTimes.value?.email || "" },
  });

  const submitHandler: SubmitHandler<FormValues> = async ({ email }) => {
    try {
      if (
        email === accountAPI.codeTimes.value?.email &&
        Date.now() - accountAPI.codeTimes.value.time <
          accountAPI.RESEND_CODE_DELAY
      ) {
        accountAPI.codeTimes.change({ isCanceled: false });
      } else {
        await accountAPI.sendAuthCode(email);
      }

      nextStep();
    } catch (error) {
      if (getErrorType(error)) {
        setError("email", {
          message: getFieldError(error as Error, "email"),
        });
        inputRef.current?.focus();
      } else {
        openAlert({
          message: "An error occurred while sending the email",
        });
      }
    }
  };

  return (
    <form
      className={signInModalClasses.contentForm}
      onSubmit={handleSubmit(submitHandler)}
    >
      <div>
        <div className={classes.titleWrapper}>
          <h2 className={signInModalClasses.gradientTitle}>Enter your email</h2>
          <EnvelopEmoji />
        </div>
        <p className={signInModalClasses.description}>
          Verify with your email address to maximize the benefits of Coindisco
        </p>
      </div>
      <Input
        ref={inputRef}
        autoFocus
        isBlock
        size="md"
        placeholder="Email"
        {...register("email", { required: true })}
        error={errors.email?.message}
      />
      <div className={classes.banner}>
        <PointingUpEmoji />
        If you don't have an account yet, it will be created automatically
      </div>
      <Button
        className={classes.continueButton}
        themeName="primary"
        type="submit"
        isBlock
        isLoading={isSubmitting}
        disabled={Boolean(errors.email) || !isValid}
      >
        Continue
      </Button>
    </form>
  );
};

export default SendEmail;
