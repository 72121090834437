import useDispatch from "hooks/redux/use-dispatch";

import { accountThunks } from "store/account";

export const useConnect = () => {
  const dispatch = useDispatch();

  return {
    verifyAuthCode: (email: string, code: string) =>
      dispatch(accountThunks.verifyAuthCode({ code, email })),
  };
};
