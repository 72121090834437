import { FC, useEffect, useRef, useState } from "react";
import { format } from "date-fns";
import { useFormContext } from "react-hook-form";

import accountAPI from "api/account";

import Button from "basics/button";

import { openAlert } from "components/dialog";

import { getFieldError } from "selectors/errors";

import { getTimerTick } from "./duck/selectors";

import classes from "./styles/classes.module.scss";

interface Props {
  email: string;
}

const ResendButton: FC<Props> = ({ email }) => {
  const [tick, setTick] = useState<number | null>(getTimerTick);
  const [loading, setLoading] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const form = useFormContext();

  const resetTimer = () => {
    setTick(null);
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
  };

  const handleTimer = (tick = getTimerTick()) => {
    if (!tick || tick < 1000) {
      return resetTimer();
    }

    setTick(tick);
    timeoutRef.current = setTimeout(() => handleTimer(tick - 1000), 1000);
  };

  useEffect(() => {
    handleTimer();

    return resetTimer;
  }, []);

  return (
    <Button
      className={classes.button}
      themeName="ghost"
      onClick={async () => {
        form.clearErrors("resend");
        setLoading(true);
        try {
          await accountAPI.sendAuthCode(email!);

          handleTimer();
        } catch (error) {
          form.setError("resend", {
            message: getFieldError(error as Error, "email"),
          });
          openAlert({ message: "An error occurred while sending the code" });
        }

        setLoading(false);
      }}
      disabled={tick !== null || loading}
      isBlock
    >
      {tick
        ? `Didn't receive it? Resend code in: ${format(tick, "mm:ss")}`
        : "Resend code"}
    </Button>
  );
};

export default ResendButton;
