import joinClassNames from "classnames";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";

import accountAPI from "api/account";

import Button from "basics/button";

import { getErrorType, getFieldError } from "selectors/errors";

import { openAlert } from "components/dialog";

import ResendButton from "./components/resend-button";
import VerifyCodeInput from "./components/verify-code-input";

import { useConnect } from "./duck/hooks";
import { FormValues } from "./duck/types";

import signInModalClasses from "../../styles/classes.module.scss";
import classes from "./styles/classes.module.scss";

interface Props {
  nextStep: VoidFunction;
}

const VerifyCode = ({ nextStep }: Props) => {
  const form = useForm<FormValues>({
    defaultValues: { code: "" },
  });
  const { verifyAuthCode } = useConnect();
  const email = accountAPI.codeTimes.value?.email;

  const {
    setError,
    handleSubmit,
    formState: { isSubmitting, isValid },
  } = form;

  const submitHandler: SubmitHandler<FormValues> = async ({ code }) => {
    try {
      await verifyAuthCode(email!, code).unwrap();

      nextStep();
    } catch (error) {
      if (getErrorType(error)) {
        setError("code", {
          message: getFieldError(error as Error, "nonFieldErrors"),
        });
      } else {
        openAlert({ message: "An error occurred while sending the code" });
      }
    }
  };

  const code = form.watch("code");

  return (
    <FormProvider {...form}>
      <form
        className={joinClassNames(
          signInModalClasses.contentForm,
          classes.contentForm,
        )}
        onSubmit={handleSubmit(submitHandler)}
      >
        <div className={classes.textWrapper}>
          <h2 className={signInModalClasses.gradientTitle}>
            Enter verification code
          </h2>
          <p className={signInModalClasses.description}>
            Please enter the verification code sent to{" "}
            <span className={classes.email}>{email}</span>
          </p>
        </div>
        <div className={classes.codeWrapper}>
          <VerifyCodeInput onFilled={() => handleSubmit(submitHandler)()} />
          <ResendButton email={email!} />
        </div>
        <Button
          className={classes.verifyButton}
          themeName="primary"
          isLoading={isSubmitting}
          type="submit"
          disabled={code.length < 6 || !isValid}
          isBlock
        >
          Verify
        </Button>
      </form>
    </FormProvider>
  );
};

export default VerifyCode;
