import { useRef } from "react";
import { useController, useFormContext } from "react-hook-form";

import { CodeInput } from "basics/input";

import classes from "./styles/classes.module.scss";

interface Props {
  onFilled: (value: string) => void;
}

const VerifyCodeInput = ({ onFilled }: Props) => {
  const codeInputRef = useRef<HTMLInputElement[]>([]);
  const {
    formState: { errors },
    resetField,
  } = useFormContext();
  const {
    field,
    fieldState: { error },
    formState: { isSubmitting },
  } = useController({
    name: "code",
  });

  const errorMessage = (error?.message || errors?.resend?.message) as string;

  return (
    <>
      <CodeInput
        className={classes.codeInput}
        name="code"
        inputMode="numeric"
        autoFocus
        readOnly={isSubmitting}
        onFilled={onFilled}
        classNames={{ input: classes.codeInputItem }}
        wrapperProps={{
          "aria-invalid": Boolean(error),
          onAnimationEnd: () => {
            if (error) {
              resetField("code", { keepError: true });
              codeInputRef.current[0].focus();
            }
          },
        }}
        {...field}
        ref={codeInputRef}
      />
      {errorMessage && <p className={classes.errorMessage}>{errorMessage}</p>}
    </>
  );
};

export default VerifyCodeInput;
